<template>
	<div class="miss"></div>
</template>

<script>
export default {
	name: 'Kunshan404'
}
</script>

<style lang="less" scoped>
	.miss {
		width: 100%;
		height: 100vh;
		background: url(../../static/image/kunshan-404.png) no-repeat;
		background-size: 100% 100%;
	}
</style>
